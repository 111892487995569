<template>
  <router-view :key="$route.fullPath"/>
</template>

<script>
export default {
  name: 'User',
  beforeRouteEnter (to, from, next) {
    next()
  }
}
</script>

<style scoped>

</style>
